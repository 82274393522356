<template>
    <div>
        <div v-for="(value, year) in JSON.parse(row.item.custom_cumulative_profit)"><b>{{ year }}</b>:
            {{ value }}&euro;
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>